<script>
import TemplateHeaderTableNew from '@/components/TemplateHeaderTableList'
import { getAction, downloadAction } from '@/command/netTool'
import { hotelMixin } from '@/mixins/orderProcessing/hotelMixin'
import { eatDrinkMixin } from '@/mixins/orderProcessing/eatDrinkMixin'
import { farmingExperienceMixin } from '@/mixins/orderProcessing/farmingExperienceMixin'
import { scenicAreasTicketsMixin } from '@/mixins/orderProcessing/scenicAreasTickets'
import { localProductsMixin } from '@/mixins/orderProcessing/localProductsMixin'
import { Tooltip } from 'ant-design-vue'
import Big from 'big.js'

export default {
  name: 'dataReportScenic',
  data() {
    return {
      records: [],
      totalData: null,
      current: 1,
      total: 1,
      // listUrl: '/reportForm/getTicketScenicOrderPageV3',
      params: { current: 1, pageSize: 10 },
      paramsValue: {},
      orderIdList: [],
    }
  },
  mixins: [hotelMixin, eatDrinkMixin, farmingExperienceMixin, scenicAreasTicketsMixin, localProductsMixin],
  mounted() {
    this.getList()
  },
  methods: {
    getTotal(params) {
      return new Promise((resolve) => {
        // if (this.totalData) {
        //   resolve(this.totalData)
        // } else {
        this.params = {
          ...this.params,
          ...params,
        }
        getAction('/reportForm/getTicketScenicOrderTotalV3', this.params).then((e) => {
          this.totalData = e.data
          resolve(e.data)
        })
        // }
      })
    },
    getSum(list) {
      let orderCount = 0
      let productQuantityCompleted = 0
      let productQuantity = 0
      let totalAmount = 0
      let totalAmountCompleted = 0
      let refundCount = 0
      let refundAmount = 0
      let salesJsAmount = 0
      let feeAmount = 0
      list.forEach((e) => {
        orderCount = parseFloat(new Big(e.orderCount || 0).plus(orderCount))
        productQuantityCompleted = parseFloat(new Big(e.productQuantityCompleted || 0).plus(productQuantityCompleted))
        productQuantity = parseFloat(new Big(e.productQuantity || 0).plus(productQuantity))
        totalAmount = parseFloat(new Big(e.totalAmount || 0).plus(totalAmount))
        totalAmountCompleted = parseFloat(new Big(e.totalAmountCompleted || 0).plus(totalAmountCompleted))
        refundCount = parseFloat(new Big(e.refundCount || 0).plus(refundCount))
        refundAmount = parseFloat(new Big(e.refundAmount || 0).plus(refundAmount))
        salesJsAmount = parseFloat(new Big(e.salesJsAmount || 0).plus(salesJsAmount))
        feeAmount = parseFloat(new Big(e.feeAmount || 0).plus(feeAmount))
      })
      return {
        businessName: '合计',
        orderCount,
        productQuantityCompleted,
        productQuantity,
        totalAmount,
        totalAmountCompleted,
        refundAmount,
        refundCount,
        salesJsAmount,
        feeAmount,
      }
    },
    getList(params = this.params) {
      this.getTotal(params).then((total) => {
        getAction('/reportForm/getTicketScenicOrderPageV3', params).then((e) => {
          this.params = { ...params }
          this.records = e.data['records']
          this.params['current'] = e.data['current']
          this.params['pageSize'] = e.data['pageSize']
          this.current = e.data['current']
          this.total = e.data['total']
          if (this.records.length !== 0) {
            //  this.records.push(this.getSum(e.data['records']))
            // this.records.push({
            //   orderCount: total.orderCountHj,
            //   totalAmount: total.totalAmountHj,
            //   productQuantity: total.productQuantityHj,
            //   productQuantityCompleted: total.productQuantityCompletedHj,
            //   totalAmountCompleted: total.totalAmountCompletedHj,
            //   refundCount:total.refundCountHj,
            //   refundAmount:total.refundAmountHj,
            //   productName: '合计',
            //   type: '1',
            //   salesJsAmount: total.salesJsAmountHj,
            //   profitAmount: total.profitAmountHj,
            // })
            this.records.push({ ...total, productName: '总计', feeAmount: total.feeAmount })
          }
        })
      })
    },
    getHeader() {
      return [
        {
          name: '日期',
          type: 'rangePicker',
          keys: ['dateGe', 'dateLe'],
        },
        {
          type: 'input',
          name: '景区名称',
          key: 'businessName',
        },
        {
          type: 'select',
          name: '订单状态',
          key: 'status',
          typeData: [
            {
              name: '待使用',
              value: 1,
            },
            {
              name: '已完成',
              value: 5,
            },
            {
              name: '已关闭',
              value: 6,
            },
          ],
        },
      ]
    },
    getColumns() {
      return [
        {
          title: '出售门票',
          dataIndex: 'productName',
          customRender: (text, records) => {
            return (
              <div class="product-order-item-copy" style={{ width: '200px' }}>
                {records.productPic ? <img src={records.productPic.split(',')[0]} /> : ''}
                <Tooltip title={text}>
                  <div>{text}</div>
                </Tooltip>
              </div>
            )
          },
        },
        {
          title: '景点名称',
          dataIndex: 'businessName',
          align: 'center',
          customRender: (text, records) => {
            return (
              <div class="product-order-item-copy" style={{ width: '100px' }}>
                <Tooltip title={text}>
                  <div>{text}</div>
                </Tooltip>
              </div>
            )
          },
        },
        {
          title: '购买人',
          dataIndex: 'buyName',
        },
        {
          title: '手机号',
          dataIndex: 'buyPhone',
        },
        {
          title: '下单时间',
          dataIndex: 'createTime',
        },
        {
          title: '数量',
          dataIndex: 'productQuantity',
        },
        {
          title: '核销时间',
          dataIndex: 'usedTime',
          customRender: (text, records) => {
            return text || '-'
          },
        },
        {
          title: '订单金额',
          dataIndex: 'totalAmount',
          customRender: (text, records) => {
            let newText = text
            if (records.refundAmount) {
              newText = newText + `(退款:${records.refundAmount})`
            }
            return newText
          },
        },
        {
          title: '退款数量',
          dataIndex: 'refundCount',
        },
        {
          title: '退款金额',
          dataIndex: 'refundAmount',
        },
        {
          title: '核销数量',
          dataIndex: 'productQuantityCompleted',
        },
        {
          title: '核销金额',
          dataIndex: 'totalAmountCompleted',
          sorter: (a, b, sortOrder) => {
            if (a.productName == '合计' || a.productName == '总计') {
              if (sortOrder == 'ascend') {
                return 9999999999999
              } else if (sortOrder == 'descend') {
                return -10000
              } else {
                return 99999999999999
              }
            } else {
              return a.totalAmountCompleted - b.totalAmountCompleted
            }
          },
          customRender: (text) => {
            if (!text) return '0.0'
            return <span>{parseFloat(text).toFixed(2)}</span>
          },
        },
        {
          title: '手续费',
          dataIndex: 'feeAmount',
          sorter: (a, b, sortOrder) => {
            if (a.createTime == '合计' || a.createTime == '总计') {
              if (sortOrder == 'ascend') {
                return 9999999999999
              } else if (sortOrder == 'descend') {
                return -10000
              } else {
                return 99999999999999
              }
            } else {
              return a.feeAmount - b.feeAmount
            }
          },
        },
        {
          title: '结算金额',
          dataIndex: 'salesJsAmount',
          sorter: (a, b, sortOrder) => {
            if (a.productName == '合计' || a.productName == '总计') {
              if (sortOrder == 'ascend') {
                return 9999999999999
              } else if (sortOrder == 'descend') {
                return -10000
              } else {
                return 99999999999999
              }
            } else {
              return a.salesJsAmount - b.salesJsAmount
            }
          },
        },
        {
          title: '订单状态',
          dataIndex: 'status',
          customRender: (data) => {
            const state =
              data === '102' ? '待确认' : ['待支付', '待使用', '待发货', '待签收', '待评价', '已完成', '已关闭'][data]
            return <div>{state}</div>
          },
        },
        {
          dataIndex: 'a1',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          typeData: ({ records }) => {
            return [
              {
                display: !!records.businessId,
                name: '详情',
                onClick: () => {
                  // this.$router.push(`/dataReport/scenicDetail?businessId=${records.businessId}`)
                  // 订单来源：1农家特产 2 农家乐 4农事体验 12 景点门票 13 酒店民宿
                  const fromType = records.fromType
                  switch (fromType) {
                    case '1':
                      this.onSelectRowLocalProducts(records)
                      break
                    case '2':
                      this.onRowSelectEatDrink(records.id)
                      break
                    case '4':
                      this.onRowSelectFarmingExperience(records.id)
                      break
                    case '12':
                      this.onRowSelectScenicAreasTickets(records, null, true)
                      break
                    case '13':
                    case '14':
                      this.onRowSelectHotel(records)
                      break
                  }
                },
              },
            ].filter((e) => e.display)
          },
        },
      ]
    },
    onTransferData(data) {
      const list = data
      let productQuantity = 0
      let productQuantityCompleted = 0
      list.forEach((e) => {
        productQuantity += e.productQuantity
        productQuantityCompleted += e.productQuantityCompleted
      })
      list.push({ productQuantity, productQuantityCompleted })
      return data
    },
    onChange(data, filter) {
      this.getList(data)
    },
    getRightButton() {
      return [
        {
          name: '导出Excel',
          type: 'normal2',
          onClick: (data) => {
            const { businessName, status, dateGe, dateLe } = this.params
            console.log(this.params)
            let urlParam = ''
            if (businessName) {
              urlParam = urlParam + `businessName=${businessName}`
            }
            if (status) {
              urlParam = (urlParam ? `${urlParam}&` : urlParam) + `status=${status}`
            }
            if (this.orderIdList.length) {
              urlParam = (urlParam ? `${urlParam}&` : urlParam) + `&orderIdList=${this.orderIdList}`
            }
            if (dateGe) {
              urlParam = (urlParam ? `${urlParam}&` : urlParam) + `&dateGe=${dateGe}&dateLe=${dateLe}`
            }
            downloadAction(`/api/reportForm/exportTicketScenicReportV3?${urlParam}`, '景区报表.xlsx')
          },
        },
      ]
    },
    selectRow(data, records) {
      this.orderIdList = records.map((d) => d.id)
    },
  },
  render() {
    return (
      <TemplateHeaderTableNew
        records={this.records}
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        onChange={this.onChange}
        rightButton={this.getRightButton()}
        button={[]}
        filterRightButtonKey={['refresh', 'setting']}
        onSelectRow={this.selectRow}
      />
    )
  },
}
</script>
